import React, {useState, useEffect} from "react";
import axios from 'axios';
import Footer from "../components/Footer";
import QuoteSection from "../components/QuoteSection";
import DonationForm from "../DonationForm";
import Contact from "../components/Contact";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import "./DonatePage.css";
import NavbarContextProvider from "../utils/NavbarContextProvider";
import ProgressBar from "@ramonak/react-progress-bar";
const API_BASE_URL = "https://bound-backend-4a015fad238e.herokuapp.com"; 

export default function DonatePage() {
  const [campaigns, setCampaigns] = useState([]);
  const [donations, setDonations] = useState([]);
  
  useEffect(() => {
    // Fetch campaign progress data using Axios when the component mounts
    const fetchCampaignProgress = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/progress`);
        setCampaigns(response.data);
      } catch (error) {
        console.error('Failed to fetch campaign progress:', error);
      }
    };
    fetchCampaignProgress();
  }, []); // Th

  const fetchDonations = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/donations`); // Adjust the URL if necessary
      const updatedDonations = response.data.map(donation => ({
        ...donation,
        donor_name: donation.donor_name === '' ? "Anonymous" : donation.donor_name, // Set to "Anonymous" if donor_name is an empty string
      }))
      .reverse();
      setDonations(updatedDonations);
    } catch (error) {
      console.error('Failed to fetch donations:', error);
    }
  };

  useEffect(() => {
    fetchDonations();
  }, []);

  return (
    <NavbarContextProvider>
      <div className="donatePage">
        <Navbar/>

        {/* <Hero /> */}
        <div id="campaign" className="reliefContainer">
          <div className="reliefTextConatienr">
            <h1 className="reliefTitleText">Current Campaign</h1>

            <div className="reliefParagrapghTextContainer">
              <h3 className="reliefParagrapghText">
              <strong>From David Aronie Sofer in Great Neck:</strong><br></br><br></br>

        
              BH a chatan was married this year, got his tefilin checked and found out they were not kosher. His current financial situation does not allow for him to purchase a new pair due to all the expenses of getting married. He helps many people by doing kiruv trips and is trying to build his new home with his kallah
              <br></br><br></br>Let's come together to support him. The funds we raise will help him receive a beautiful pair of tefillin.
            
              </h3>
            </div>
          </div>
        </div>
        {campaigns.map((campaign) => (
          <div className="goal-cnt">
            <h1 className="reliefTitleText">${campaign.total_donations}</h1>
            <ProgressBar completed={+campaign.percentage_collected} bgColor="#000" className="pbs"/>
            <h4 className="reliefTitleSubText">Goal: ${campaign.goal}</h4>
          </div>
        ))}
        <div className="recent-donations-cnt">
          <h2 className="title-txt">Recent Donations</h2>
          {donations.map((donation) => (
            <div key={donation.donation_id} className="donation-row">
              <p className="name">{donation.donor_name}</p>
              <p className="message">{donation.message}</p>
              <p className="amount">${donation.amount}</p>
            </div>
          ))}
        </div>

        <section className="donationFormSection">
          <DonationForm />
        </section>

        <QuoteSection
          backgroundColor={"#101720"}
          textColor={"#131f67"}
        >
          “A cherished pair of tefillin holds immense importance for Jewish boys and men. It's disheartening to realize that some Jewish families face the financial strain of acquiring this vital symbol of faith, particularly during these times of war. Our nation is currently in conflict, and despite our geographical distance from the front lines, we can't shake the feeling of helplessness. Much like our brave soldiers don their bulletproof vests before heading into battle, we too seek protection by putting on our tefillin. Just as they prepare for combat, we engage in our daily battle through prayer, beseeching Hashem for the safety and victory of the Jewish people. Our mission and dedication revolve around assisting any child or family in need without subjecting them to the embarrassment of seeking help from others. We aim to ensure that they have their own set of tefillin, empowering them to protect and stand firm in their emunah.”
        </QuoteSection>

        <section
          id="contact"
          style={{
            maxWidth: "1900px",
            margin: "0 auto",
          }}
          className="contactSection"
        >

        </section>

        <Footer />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            flexDirection: "column",
            backgroundColor: "#000",
            color: "white",
            width: "100%",
            textAlign: "center",
          }}
        >
          <div className="copyrightTextDesktop">
            <p> Copyright Davis Memorial Fund. All Rights Reserved. ©2023</p>
          </div>
        </div>
      </div>
    </NavbarContextProvider>
  );
}
